<template>
  <div>
    <div class="head">
      <!-- <div class="logo" style="height: 60px; width: 60px">
        <img v-if="logo_url" :src="logo_url" alt="" />
      </div> -->
      <img :src="banner[0].url" alt="" v-if="banner && banner.length == 1" />
      <div class="block" v-else>
        <el-carousel height="300px" v-if="banner">
          <el-carousel-item v-for="(item, index) in banner" :key="index">
            <img :src="item.url" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- <h2>{{ title }}</h2> -->
      <h4></h4>
      <div class="shade">
        <!-- <h2>{{ title }}</h2> -->
        <h4></h4>
      </div>
    </div>
    <div class="nav" :style="{ background: bgcClo }">
      <div class="content">
        <div class="div" v-for="(item, index) in dataList" :key="index" @click="tab(index, item)"
          :class="[currentIndex == index ? 'current' : '']"
          style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
          <el-tooltip class="item" effect="dark" :content="item.name" placement="top-start">
            <span v-if="index != 0">
              {{ item.name }}
            </span>
          </el-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bgcClo: {
      default: "#56b3b6",
    },
    dataList: {
      default: [
        { name: "回到首页" },
        { name: "首页" },
        {
          id: 68,
          project_id: 51,
          name: "出行类",
          c_time: 1641545871,
          status: "1",
          title: "老年人智能运用技术",
        },
        {
          id: 69,
          project_id: 51,
          name: "就医类",
          c_time: 1641545916,
          status: "1",
          title: "老年人智能运用技术",
        },
        {
          id: 70,
          project_id: 51,
          name: "消费类",
          c_time: 1641545926,
          status: "1",
          title: "老年人智能运用技术",
        },
        {
          id: 71,
          project_id: 51,
          name: "欣赏类",
          c_time: 1641894010,
          status: "1",
          title: "老年人运用智能技术培训资源库",
        },
      ],
    },
    banner: {},
    title: {},
  },
  data() {
    return {
      logo_url: JSON.parse(localStorage.getItem("themeOther")).logo_url,

      navList: [],
      currentIndex: 1,
      show: true,
      color: this.$store.state.themeOther.color,
    };
  },

  mounted() {
    this.getdata();
    console.log(123);
  },

  methods: {
    tab(index, item) {
      this.currentIndex = index;
      this.$emit("tabs", { index, item });
    },
    goindex() {
      this.$router.push({ path: `/${localStorage.getItem("urlL")}` });
    },
    getdata() {
      // index_list({ zl_url: "szyx", project_id: 51 }).then((res) => {
      //   console.log(res, 11111);
      // });
    },
  },
  watch: {
    dataList: {
      handler: function (newValue) {
        console.log(newValue, 555);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../style/default/default.scss";

.head {
  position: relative;

  .logo {
    position: absolute;
    left: 40px;
    top: 40px;
    z-index: 999;
  }

  img {
    width: 100%;
    height: 100%;
  }

  .block {
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }

  font-family: Microsoft YaHei;
  font-weight: 400;
  width: 100%;
  height: 18.75rem;
  color: white;
  position: relative;

  h2 {
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    top: 80px;
    z-index: 12;
  }

  h4 {
    position: absolute;
    top: 5px;
    right: 3%;
    z-index: 12;

    &:hover {
      cursor: pointer;
    }

    span {
      height: 48px;
      border-radius: 24px;
      font-size: 16px;
      color: $brown;
      padding: 13px 30px;

      &:hover .shade {}
    }
  }

  .shade {
    position: absolute;
    left: 0;
    top: 0;
    background: black;
    opacity: 0;
    width: 100%;
    height: 100%;
    transition: 1s all;

    &:hover {
      opacity: 0.3;
    }
  }
}

.nav {
  width: 100%;
  height: 56px;
  background: $brown;
  line-height: 56px;
  display: flex;
  justify-content: space-evenly;

  .content {
    color: white;
    width: 1400px;

    div {
      float: left;
      width: 150px;
      text-align: center;
      line-height: 56px;
      margin-left: 50px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.current {
  font-weight: bold;
}
</style>
