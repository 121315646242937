<template>
  <div class="header">
    <!-- <div class="logo" >
      <img :src="logo_url" alt="">
      </div> -->
    <div class="banner">
      <span @click="goHome">返回网站首页</span>
      <img :src="banner[0].url" alt="" v-if="banner.length == 1" />
    </div>
    <div class="top" v-if="!show">
      <span>{{ title }}</span>
      <span @click="isShow" style="float:right"
        ><van-icon name="wap-nav"
      /></span>
    </div>
    <div class="tab" v-else>
      <div class="title">
        <span>{{ title }}</span>
        <span @click="isShow"><i class="el-icon-close"></i></span>
      </div>
      <van-collapse
        v-model="activeName"
        accordion
        class="dym_collapse"
        @change="Mobopen"
      >
        <van-collapse-item
          class="collapse_item"
          @click="Mobopen(index + 1)"
          :title="item.name"
          :name="index + 1"
          v-for="(item, index) in dataList"
          :key="index"
        ></van-collapse-item>
      </van-collapse>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataList: [],
    banner: [{ url: "" }],
    title: {},
  },
  data() {
    return {
      activeName: ["1", "2", "3"],
      show: false,
      logo_url: JSON.parse(localStorage.getItem("themeOther")).logo_url,
    };
  },
  mounted() {},
  methods: {
    isShow() {
      this.show = !this.show;
    },
    goHome() {
      this.$router.push({ path: `/${localStorage.getItem("urlL")}` });
    },
    Mobopen(value) {
      this.$emit("mobopen", value);
      this.show = !this.show;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../style/default/default.scss";
.header {
padding-top: 60px;
  color: $brown;
  box-sizing: border-box;
  width: 100%;
  height: 18.75rem;
  max-width: 780px;
  position: relative;
  background-size: 100% 100%;
  position: relative;
  .logo {
    position: absolute;
    top: 6.375rem;
    width: 3.75rem;
    z-index: 999;
    left: 20px;
    height: 3.75rem;
    img {
      height: 100%;
      width: 100%;
    }
  }
  .banner {
    font-size: 1.525rem;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 0;
    border-radius: 9rem;
    span {
      position: absolute;
      top: 50%;
      line-height: 40px;
      padding: 0 10px;
      background: white;
      border-radius: 20px;
      left: 50%;
      transform: translate(-50%);
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .top {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    z-index: 99;
    left: 0;
    padding-left: 20px;
    padding-right: 20px;
    height: 5.625rem;
    width: 100%;
    background: #ffffff;
    color: $brown;
    font-size: 1.875rem;
    line-height: 5.625rem;
    span {
      &:nth-child(1) {
        display: inline-block;
        width: 60%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: 100%;
      }
      &:nth-child(2){}
    }
  }
  .tab {
    z-index: 99;
    background: white;
    opacity: 0.8;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    .dym_collapse {
      /deep/ .collapse_item {
        .van-collapse-item__content {
          color: #b4a588;
          padding-left: 5.125rem;
          font-size: 2.125rem;
        }
        .van-cell {
          color: #333333;
          .van-cell__title {
            color: #333333;

            height: 5rem;
            font-size: 1.875rem;
            line-height: 5rem;
            .van-icon {
              color: white;
              line-height: 5rem !important ;
            }
            .van-cell__right-icon,
            .van-icon {
              line-height: 5rem;
              margin-top: 20px;
            }
          }
        }
      }
    }
    .title {
      display: flex;
      justify-content: space-between;
      height: 5.625rem;
      padding: 0 20px;
      font-size: 1.875rem;
      line-height: 5.625rem;
      border-bottom: 1px solid #cccccc;
      span {
        &:nth-child(1) {
          width: 80%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}
</style>
